import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import permissionChecker from 'containers/PermissionChecker'

import { fetchCustomClassifiers } from 'containers/DataClassifierData/actions'
import {
  selectCustomClassifiers,
  selectFetchingClassifiers,
  selectDeletingCustomClassifiers,
  selectUpdatingCustomClassifiers,
} from 'containers/DataClassifierData/selectors'

import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import Button from 'components/Button'
import Icon from 'components/Icon'
import TextLink from 'components/TextLink'

import CreateCustomModal from './CreateCustomModal'
import RegexClassifier from './RegexClassifier'
import IHelp from 'containers/IHelp'

export class DataClassifierManagement extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      createModalOpen: false,
    }
    this.styles = {
      container: {
        padding: '1em',
        width: '100%',
        height: '100%',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
      },
      header: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        marginBottom: '1em',
        columnGap: '0.5em',
      },
      cardZone: {
        overflow: 'auto',
      },
    }

    if (props.customClassifiers.isEmpty() && !props.loading) {
      props.fetchCustomClassifiers()
    }
  }

  openCreateModal = () => {
    this.setState({ createModalOpen: true })
  }

  closeCreateModal = () => {
    this.setState({ createModalOpen: false })
  }

  renderClassifiers = canMutate => {
    if (this.props.loading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <SquareLoadingAnimation />
        </div>
      )
    } else {
      if (this.props.customClassifiers.size < 1) {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            No Custom Classifiers have been found.&nbsp;
            {canMutate && (
              <Fragment>
                <TextLink color="primary" onClick={this.openCreateModal}>
                  Click Here
                </TextLink>
                &nbsp;to create one now.
              </Fragment>
            )}
          </div>
        )
      } else {
        return this.props.customClassifiers.map((cl, index) => (
          <RegexClassifier
            key={`${index}-classifier`}
            classifier={cl}
            deleting={this.props.deleting.get(cl.get('srn'))}
            updating={this.props.updating.get(cl.get('srn'))}
            canEdit={canMutate}
          />
        ))
      }
    }
  }

  render() {
    const canMutate = this.props.userHasPermission({
      permissionName: 'edit.customclassifiers',
    })

    return (
      <div style={this.styles.container}>
        <div style={this.styles.header}>
          <div style={{ fontSize: '1.5em' }}>Custom Data Classifiers</div>
          <div style={{ width: '20px', marginTop: '0.4em' }}>
            <IHelp
              id="customDataClassifierDescription"
              help
              position="left"
              helpKey="customDataClassifierDescription"
            />
          </div>
          {canMutate ? (
            <Button color="primary" onClick={this.openCreateModal}>
              <Icon style={{ marginRight: '0.5em' }} fa name="plus" />
              Create Custom Classifier
            </Button>
          ) : (
            <div>&nbsp;</div>
          )}
        </div>
        <div style={this.styles.cardZone}>
          {this.renderClassifiers(canMutate)}
        </div>
        {this.state.createModalOpen && (
          <CreateCustomModal
            isOpen={this.state.createModalOpen}
            close={this.closeCreateModal}
          />
        )}
      </div>
    )
  }
}

DataClassifierManagement.propTypes = {
  fetchCustomClassifiers: PropTypes.func,
  customClassifiers: ImmutablePropTypes.list,
  deleting: ImmutablePropTypes.map,
  updating: ImmutablePropTypes.map,
  loading: PropTypes.bool,
  userHasPermission: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  customClassifiers: selectCustomClassifiers,
  loading: selectFetchingClassifiers,
  deleting: selectDeletingCustomClassifiers,
  updating: selectUpdatingCustomClassifiers,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchCustomClassifiers,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, permissionChecker)(DataClassifierManagement)
