import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import {
  deleteCustomClassifier,
  updateCustomClassifiers,
} from 'containers/DataClassifierData/actions'

import BorderedCard from 'components/BorderedCard'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import FormLabel from 'components/FormLabel'
import Button from 'components/Button'
import Icon from 'components/Icon'
import IHelp from 'containers/IHelp'
import Select from 'react-select'
import Creatable from 'react-select/creatable'
import { Input } from 'reactstrap'
import { startCase, camelCase } from 'lodash'

const whereOptions = [
  { value: 'left', label: 'Left' },
  { value: 'right', label: 'Right' },
]

const getCoolObjectGuy = value => {
  return { value, label: startCase(value) }
}

const getRegularObjectGuy = value => {
  return { value, label: value }
}

export const RegexClassifier = props => {
  const [atomName, setAtomName] = useState(
    props.classifier
      ? props.classifier.get('atomName')
        ? props.classifier.get('atomName')
        : ''
      : ''
  )
  const [aliases, setAliases] = useState(
    props.classifier
      ? (props.classifier.get('params').get('aliases')
          ? props.classifier.getIn(['params', 'aliases']).toJS()
          : []
        ).map(guy => getRegularObjectGuy(guy))
      : []
  )
  const [disAliases, setDisAliases] = useState(
    props.classifier
      ? (props.classifier.get('params').get('disallowed_aliases')
          ? props.classifier.getIn(['params', 'disallowed_aliases']).toJS()
          : []
        ).map(guy => getRegularObjectGuy(guy))
      : []
  )
  const [regexes, setRegexes] = useState(
    props.classifier
      ? (props.classifier.get('params').get('regexes')
          ? props.classifier.getIn(['params', 'regexes']).toJS()
          : []
        ).map(guy => getRegularObjectGuy(guy))
      : []
  )
  const [proximity, setProximity] = useState(
    props.classifier
      ? props.classifier.getIn(['params', 'proximity'], null)
      : null
  )
  const [proximityValid, setProximityValid] = useState(true)
  const [where, setWhere] = useState(
    props.classifier
      ? (props.classifier.get('params').get('where')
          ? props.classifier.getIn(['params', 'where']).toJS()
          : []
        ).map(guy => getCoolObjectGuy(guy))
      : []
  )
  const [type, setType] = useState(
    props.classifier ? props.classifier.get('type') : 'REGEX'
  )

  const updateAtomName = text => {
    if (text === '') {
      setAtomName('')
    } else if (/^[a-zA-Z0-9]+$/.test(text)) {
      setAtomName(camelCase(text.trim()))
    }
  }

  useEffect(() => {
    if (proximity) {
      if (/^\d+$/.test(proximity)) {
        setProximityValid(true)
      } else {
        setProximityValid(false)
      }
    }
  }, [proximity])

  const isDifferent = () => {
    if (!props.classifier) {
      return true
    }
    // if type doesnt match it auto different
    if (props.classifier.get('type') !== type) {
      return true
    }
    let isDiff = false
    // check name
    if (atomName !== props.classifier.get('atomName')) {
      isDiff = true
    }
    // check regexes
    if (
      props.classifier.get('params').get('regexes') &&
      props.classifier.get('params').get('regexes').size !== regexes.length
    ) {
      isDiff = true
    } else if (props.classifier.get('params').get('regexes')) {
      props.classifier
        .get('params')
        .get('regexes')
        .forEach(rx => {
          if (regexes.findIndex(reg => reg.value === rx) === -1) {
            isDiff = true
          }
        })
    } else {
      regexes.forEach(rx => {
        if (!props.classifier.get('params').get('regexes')) {
          isDiff = true
        } else {
          if (
            props.classifier
              .get('params')
              .get('regexes')
              .findIndex(reg => reg === rx.value) === -1
          ) {
            isDiff = true
          }
        }
      })
    }
    // return if type REGEX
    if (type === 'REGEX') {
      return isDiff
    }
    // check aliases
    if (
      props.classifier.get('params').get('aliases') &&
      props.classifier.get('params').get('aliases').size !== aliases.length
    ) {
      isDiff = true
    } else if (props.classifier.get('params').get('aliases')) {
      props.classifier
        .get('params')
        .get('aliases')
        .forEach(al => {
          if (aliases.findIndex(alias => alias.value === al) === -1) {
            isDiff = true
          }
        })
    } else {
      aliases.forEach(al => {
        if (!props.classifier.get('params').get('aliases')) {
          isDiff = true
        } else {
          if (
            props.classifier
              .get('params')
              .get('aliases')
              .findIndex(alias => alias === al.value) === -1
          ) {
            isDiff = true
          }
        }
      })
    }
    // check disallowed_aliases
    if (
      props.classifier.get('params').get('disallowed_aliases') &&
      props.classifier.get('params').get('disallowed_aliases').size !==
        disAliases.length
    ) {
      isDiff = true
    } else if (props.classifier.get('params').get('disallowed_aliases')) {
      props.classifier
        .get('params')
        .get('disallowed_aliases')
        .forEach(al => {
          if (disAliases.findIndex(dAlias => dAlias.value === al) === -1) {
            isDiff = true
          }
        })
    } else {
      disAliases.forEach(da => {
        if (!props.classifier.get('params').get('disallowed_aliases')) {
          isDiff = true
        } else {
          if (
            props.classifier
              .get('params')
              .get('disallowed_aliases')
              .findIndex(dAlias => dAlias === da.value) === -1
          ) {
            isDiff = true
          }
        }
      })
    }
    // check proximity
    if (props.classifier.getIn(['params', 'proximity'], null) != proximity) {
      isDiff = true
    }
    // check where
    if (
      props.classifier.get('params').get('where') &&
      props.classifier.get('params').get('where').size !== where.length
    ) {
      isDiff = true
    } else if (props.classifier.get('params').get('where')) {
      props.classifier
        .get('params')
        .get('where')
        .forEach(al => {
          if (where.findIndex(wh => wh.value === al) === -1) {
            isDiff = true
          }
        })
    } else {
      where.forEach(da => {
        if (!props.classifier.get('params').get('where')) {
          isDiff = true
        } else {
          if (
            props.classifier
              .get('params')
              .get('where')
              .findIndex(wh => wh === da.value) === -1
          ) {
            isDiff = true
          }
        }
      })
    }
    return isDiff
  }

  const styles = {
    form: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      rowGap: '1em',
      columnGap: '2em',
    },
    label: {
      fontSize: '0.9em',
      alignSelf: 'center',
    },
    inputSpace: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      columnGap: '0.25em',
    },
    card: {
      // height: '150px',
      marginBottom: '1em',
    },
    thingy: {
      fontSize: '0.9em',
      fontWeight: '400',
      margin: '0 0 0.2em 0',
    },
    clicky: {
      fontWeight: '400',
      cursor: 'pointer',
      height: '1.4em',
      alignSelf: 'center',
    },
  }

  const updateAliases = params => {
    if (params) {
      setAliases(params)
    } else {
      setAliases([])
    }
  }

  const updateDisAliases = params => {
    if (params) {
      setDisAliases(params)
    } else {
      setDisAliases([])
    }
  }

  const updateWhere = params => {
    if (params) {
      setWhere(params)
    } else {
      setWhere([])
    }
  }

  const updateRegexes = params => {
    if (params) {
      setRegexes(params)
    } else {
      setRegexes([])
    }
  }

  const checkIfGood = () => {
    if (!atomName || atomName === '' || (atomName && atomName.includes(' '))) {
      return false
    }
    if (!regexes || regexes.length < 1) {
      return false
    }
    if (type === 'REGEX_WITH_PROXIMITY') {
      if (aliases.length < 1 && disAliases.length < 1) {
        return false
      }
      if (!proximityValid) {
        return false
      }
    }
    return true
  }

  const runUpdate = () => {
    if (isDifferent()) {
      let params = { regexes: regexes.map(r => r.value) }
      if (type === 'REGEX_WITH_PROXIMITY') {
        params.aliases = aliases.map(a => a.value)
        params.proximity =
          proximity !== '' && proximity !== null ? proximity : '100'
        params.where = where.map(w => w.value)
        params.disallowed_aliases = disAliases.map(d => d.value)
        if (!params.where || params.where.length < 1) {
          params.where = ['left']
        }
      }
      props.updateCustomClassifiers({
        srnList: [props.classifier.get('srn')],
        updated: {
          atomName,
          type,
          params: params,
        },
      })
    }
  }

  const runCreate = () => {
    let params = { regexes: regexes.map(r => r.value) }
    if (type === 'REGEX_WITH_PROXIMITY') {
      params.aliases = aliases.map(a => a.value)
      params.proximity =
        proximity !== '' && proximity !== null ? proximity : '100'
      params.where = where.map(w => w.value)
      params.disallowed_aliases = disAliases.map(d => d.value)
      if (!params.where || params.where.length < 1) {
        params.where = ['left']
      }
    }

    props.create({
      create: {
        atomName,
        type,
        params: params,
      },
    })
  }

  return (
    <BorderedCard style={styles.card}>
      <div
        style={{
          ...styles.form,
          // marginBottom: '0.5em',
          background: props.theme.neutralLight,
          padding: '0.5em',
        }}
      >
        <div>
          <FormLabel
            label="Name: (Alphanumeric with no spaces)"
            for="name"
            requireds
            style={styles.thingy}
          />

          <Input
            type="text"
            value={atomName}
            onChange={params => updateAtomName(params.target.value)}
            readOnly={!props.canEdit}
          />
        </div>
        <div>
          <FormLabel
            label="Regexes:"
            for="regexes"
            required
            style={styles.thingy}
          />
          <Creatable
            placeholder="Enter values and hit enter."
            isMulti
            value={regexes}
            onChange={updateRegexes}
            isDisabled={!props.canEdit}
          />
        </div>
        <div
          style={styles.clicky}
          onClick={() => {
            if (props.canEdit) {
              setType(
                type === 'REGEX_WITH_PROXIMITY'
                  ? 'REGEX'
                  : 'REGEX_WITH_PROXIMITY'
              )
            }
          }}
        >
          <span>With Proximity:&nbsp;</span>
          <input
            style={{ verticalAlign: 'middle' }}
            checked={type === 'REGEX_WITH_PROXIMITY'}
            readOnly
            type="checkbox"
          />
        </div>
      </div>
      {type === 'REGEX_WITH_PROXIMITY' && (
        <div
          style={{
            ...styles.form,
            border: `3px solid ${props.theme.neutralLight}`,
            padding: '0.25em',
          }}
        >
          <div>
            <FormLabel
              label="Allowed Key Words:"
              for="aliases"
              style={styles.thingy}
            />
            <Creatable
              placeholder="Enter values and hit enter."
              isMulti
              value={aliases}
              onChange={updateAliases}
              isDisabled={!props.canEdit}
            />
          </div>
          <div>
            <FormLabel
              label="Disallowed Keywords:"
              for="disAliases"
              style={styles.thingy}
            />
            <Creatable
              placeholder="Enter values and hit enter."
              isMulti
              value={disAliases}
              onChange={updateDisAliases}
              isDisabled={!props.canEdit}
            />
          </div>
          <div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 1fr',
                columnGap: '0.25em',
              }}
            >
              <FormLabel
                label="Proximity:"
                for="proximity"
                style={styles.thingy}
              />
              <div style={{ width: '20px' }}>
                <IHelp
                  id="proximityMessage"
                  help
                  position="left"
                  helpKey="proximityMessage"
                />
              </div>
            </div>
            <Input
              type="number"
              placeholder="Default is 100..."
              value={proximity}
              readOnly={!props.canEdit}
              onChange={params =>
                setProximity(
                  params.target.value !== '' ? params.target.value : null
                )
              }
            />
          </div>
          <div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 1fr',
                columnGap: '0.25em',
              }}
            >
              <FormLabel label="Where:" for="where" style={styles.thingy} />
              <div style={{ width: '20px' }}>
                <IHelp
                  id="whereMessage"
                  help
                  position="left"
                  helpKey="whereMessage"
                />
              </div>
            </div>
            <Select
              placeholder="Default is Left..."
              isMulti
              isDisabled={!props.canEdit}
              options={whereOptions}
              value={where}
              onChange={updateWhere}
            />
          </div>
        </div>
      )}
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
          marginTop: '1em',
        }}
      >
        {props.classifier ? (
          <Button
            onClick={() =>
              props.deleteCustomClassifier({
                srnList: [props.classifier.get('srn')],
              })
            }
            size="sm"
            outline
            color="danger"
            disabled={props.deleting || props.updating || !props.canEdit}
          >
            {props.deleting ? (
              <Icon fa name="sync" spin style={{ marginRight: '0.25em' }} />
            ) : (
              <Icon fa name="trash" style={{ marginRight: '0.25em' }} />
            )}
            Delete
          </Button>
        ) : (
          <Button
            disabled={props.deleting || props.updating || props.creating}
            onClick={props.cancel}
            size="sm"
            outline
            color="danger"
          >
            <Icon fa name="times" style={{ marginRight: '0.25em' }} />
            Cancel
          </Button>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={props.classifier ? runUpdate : runCreate}
            disabled={
              !isDifferent() ||
              props.deleting ||
              props.updating ||
              props.creating ||
              !checkIfGood() ||
              !props.canEdit
            }
            size="sm"
            color="primary"
          >
            {props.updating || props.creating ? (
              <Icon style={{ marginRight: '0.25em' }} fa spin name="sync" />
            ) : (
              <Icon style={{ marginRight: '0.25em' }} fa name="save" />
            )}
            {props.classifier ? `Save` : 'Create'}
          </Button>
        </div>
      </div>
    </BorderedCard>
  )
}

RegexClassifier.propTypes = {
  classifier: ImmutablePropTypes.map,
  updating: PropTypes.bool,
  deleting: PropTypes.bool,
  canEdit: PropTypes.bool,
  creating: PropTypes.bool,
  deleteCustomClassifier: PropTypes.func,
  updateCustomClassifiers: PropTypes.func,
  create: PropTypes.func,
  cancel: PropTypes.func,
  theme: themeShape,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteCustomClassifier,
      updateCustomClassifiers,
    },
    dispatch
  )
}

const withConnect = connect(null, mapDispatchToProps)

export default compose(themeable, withConnect)(RegexClassifier)
