import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { createCustomClassifier } from 'containers/DataClassifierData/actions'
import {
  selectCreatingClassifier,
  selectCreatingClassifierError,
} from 'containers/DataClassifierData/selectors'

import { FormattedMessage } from 'react-intl'
import messages from './messages'
import RegexClassifier from './RegexClassifier'

import { Modal, ModalHeader, ModalBody, Alert } from 'reactstrap'

export const CreateCustomModal = props => {
  const runCreate = params => {
    props.createCustomClassifier(params)
  }

  const [shouldClose, setShouldClose] = useState(false)

  useEffect(() => {
    if (props.creating) {
      setShouldClose(true)
    } else {
      if (!props.error && shouldClose) {
        setShouldClose(false)
        props.close()
      } else if (props.error) {
        setShouldClose(false)
      }
    }
  }, [props.creating])

  return (
    <Modal size="lg" isOpen={props.isOpen} toggle={props.close}>
      <ModalHeader>
        <FormattedMessage {...messages.createTitle} />
      </ModalHeader>
      <ModalBody>
        {props.error && <Alert color="danger">{`props.error`}</Alert>}
        <RegexClassifier
          cancel={props.close}
          creating={props.creating}
          create={runCreate}
          canEdit={true}
        />
      </ModalBody>
    </Modal>
  )
}

CreateCustomModal.propTypes = {
  loading: PropTypes.bool,
  isOpen: PropTypes.bool,
  creating: PropTypes.bool,
  submit: PropTypes.func,
  error: PropTypes.string,
  close: PropTypes.func,
  createCustomClassifier: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  creating: selectCreatingClassifier,
  error: selectCreatingClassifierError,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createCustomClassifier,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default withConnect(CreateCustomModal)
