/*
 * DataClassifierManagement Messages
 *
 * This contains all the text for the DataClassifierManagement component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  createTitle: {
    id: 'app.containers.DataClassifierManagement.createTitle',
    defaultMessage: 'Create Custom Classifier',
  },
})
